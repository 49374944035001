import Header from './components/header'
import BoxInput from './components/box'
function App() {
  return (
    <div>
    <Header/>
<BoxInput/>
    </div>
  );
}

export default App;
